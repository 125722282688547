<script>
    import { sendAnalytics } from "@/core/SendAnalytics";
    import ProductPrice from "@ledger/components/ProductPrice";
    import ProductCardButtons from "@ledger/components/ProductCardButtons";

    export default {
        name: "ProductCard",
        components: { ProductCardButtons, ProductPrice },
        props: {
            product: Object,
            position: Number,
        },
        data() {
            let isPreOrder = false;
            let currentOffers = this.product.offers.filter((offer) => offer.available);

            if (currentOffers.length === 0) {
                // проверка на предзаказ
                currentOffers = this.product.offers.filter((offer) => !offer.available);
                isPreOrder = true;
            }

            return {
                currentOffers,
                offer: currentOffers[0],
                offerId: currentOffers[0].id,
                previewImage: this.product.previewImage?.medium,
                price: currentOffers[0].price,
                discountPrice: currentOffers[0].discountPrice,
                discountPercent: currentOffers[0].discountPercent,
                inCart: this.product.inCart || false,
                loading: false,
                isPreOrder,
                previewImageFromOffer: "",
            };
        },
        computed: {
            productInfo() {
                let filtered = {
                    "ledger-nano-x": {
                        name: "Ledger <b>Nano X</b>",
                        desc: "Для работы на смартфоне или компьютере",
                        options: ["Bluetooth", "USB"],
                        image: "/static/ledger/products/X.png",
                    },
                    "ledger-nano-s": {
                        name: "Ledger <b>Nano S</b>",
                        desc: "Для работы на компьютере",
                        options: ["USB"],
                        image: "/static/ledger/products/S.png",
                    },
                };
                return filtered[this.product.slug];
            },
        },
        methods: {
            sendDataLayerEvent() {
                sendAnalytics.send(sendAnalytics.events.productClick, {
                    name: this.product.name,
                    offerId: this.offerId,
                    price: this.discountPrice,
                    position: this.position,
                });
            },
        },
    };
</script>

<template>
    <router-link
        @click.native.prevent="sendDataLayerEvent"
        :to="{
            name: 'Product',
            params: { slug: product.slug },
        }"
        class="ledger-card"
    >
        <span class="ledger-card__label" v-if="product.slug === 'ledger-nano-x'">Новинка</span>
        <span class="ledger-card__text">
            <span class="ledger-card__title" v-html="productInfo.name"></span>
            <span class="ledger-card__desc">{{ productInfo.desc }}</span>
            <span class="ledger-card__option">
                <span
                    class="ledger-card__option-item"
                    v-for="(option, i) in productInfo.options"
                    :key="i"
                >
                    <svg
                        v-show="option === 'Bluetooth'"
                        width="24"
                        height="24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            d="M12 0a9 9 0 0 0-9 9v6a9 9 0 1 0 18 0V9a9 9 0 0 0-9-9zm5.347 15.498-3.474-3.474 3.474-3.475-4.525-4.526h-1.6v5.349l-3.49-3.491-1.133 1.131 4.623 4.624v.776L6.6 17.034l1.132 1.132 3.491-3.492v5.35h1.6l4.525-4.526zm-4.525 2.263v-4.526l2.263 2.263-2.263 2.263zm2.263-9.212-2.263 2.262V6.286l2.263 2.263z"
                            fill="#142533"
                        />
                    </svg>
                    <svg
                        v-show="option === 'USB'"
                        width="24"
                        height="24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                            d="M11 6H9l3-4 3 4h-2v6h2a1 1 0 0 0 1-1h-1V7h4v4h-1a3 3 0 0 1-3 3h-2v4.268a2 2 0 1 1-2 0V17H9a3 3 0 0 1-3-3v-2.268a2 2 0 1 1 2 0V14a1 1 0 0 0 1 1h2V6z"
                            fill="#142533"
                        />
                    </svg>
                    {{ option }}
                </span>
            </span>
            <span class="ledger-card__controls">
                <product-price
                    :price="discountPrice"
                    :old-price="price"
                    :discount-count="discountPercent"
                ></product-price>

                <span class="ledger-card__buttons">
                    <product-card-buttons
                        :offer-id="offerId"
                        :product="product"
                        :is-pre-order="isPreOrder"
                        :in-cart="inCart"
                        :discount-price="discountPrice"
                        @on-added-to-cart="inCart = true"
                        size-button="md"
                    ></product-card-buttons>
                </span>
            </span>
        </span>

        <img :src="productInfo.image" alt="" />
    </router-link>
</template>

<style lang="scss">
    .ledger-card {
        width: 100%;
        margin: 0 0 16px;
        padding: 42px 0 0;
        position: relative;
        background: #f7f7f7;
        overflow: hidden;
        border: 1px solid var(--primary-border--light);
        transition: 0.3s ease border;

        @include bp($bp-tablet-md) {
            width: calc(50% - 20px);
            margin: 0 10px;
            height: 305px;
            border-radius: 4px;
            padding: 65px 0 43px 20px;
        }

        @include bp($bp-desktop-sm) {
            padding-left: 30px;
        }

        @include bp($bp-desktop-md) {
            padding-left: 49px;
        }

        img {
            display: block;
            max-width: 100%;
            height: auto;
            margin: 0 auto;
            transition: 0.3s ease transform;

            @include bp($bp-tablet-md) {
                position: absolute;
                right: 34px;
                bottom: 0;
            }

            @include bp($bp-desktop-sm) {
                right: 8px;
            }

            @include bp($bp-desktop-md) {
                transform: translateY(20px);
                right: 16px;
            }
        }

        &:hover,
        &:focus-within {
            border-color: var(--primary-border--dark);

            img {
                transform: translateY(0px);
            }

            b {
                color: var(--secondary);
            }
        }
    }

    .ledger-card__text {
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: center;
        z-index: 10;
        margin-bottom: 16px;

        @include bp($bp-tablet-md) {
            height: 100%;
            align-items: flex-start;
            margin-bottom: 0;
        }
    }

    .ledger-card__label {
        position: absolute;
        top: 20px;
        left: 0;
        width: 100%;
        text-align: center;
        font-size: 16px;
        line-height: 18px;
        color: #22a790;

        @include bp($bp-tablet-md) {
            top: 44px;
            left: 20px;

            text-align: left;
        }

        @include bp($bp-desktop-sm) {
            left: 30px;
        }

        @include bp($bp-desktop-md) {
            left: 49px;
        }
    }

    .ledger-card__title {
        font-size: 34px;
        line-height: 38px;
        margin-bottom: 5px;
        color: var(--primary--darkest);

        @include bp($bp-tablet-md) {
            font-size: 32px;
            line-height: 46px;
        }

        @include bp($bp-desktop-sm) {
            font-size: 40px;
            line-height: 44px;
            margin-bottom: 4px;
        }

        b {
            font-weight: 600;
            transition: 0.3s ease color;
        }
    }

    .ledger-card__desc {
        font-size: 16px;
        line-height: 20px;
        text-align: center;
        color: var(--secondary-text);

        @include bp($bp-tablet-md) {
            font-size: 14px;
        }

        @include bp($bp-desktop-sm) {
            font-size: 16px;
            line-height: 24px;
        }
    }

    .ledger-card__option {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin: 16px 0;

        @include bp($bp-tablet-md) {
            display: block;
            min-height: 52px;
        }
    }

    .ledger-card__option-item {
        font-size: 16px;
        line-height: 24px;
        color: var(--primary--darkest);
        display: flex;
        align-items: center;

        &:nth-child(2) {
            margin-top: 4px;
        }
        svg {
            margin-right: 2px;
        }
    }

    .ledger-card__controls {
        display: flex;
        align-items: center;

        .product-price {
            justify-content: center;
        }
    }

    .ledger-card__buttons {
        @include bp($bp-tablet-md) {
            width: 140px;
            margin-left: 12px;
        }
    }
</style>
